import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import LocalizedLink from '../components/LocalizedLink'
import useTranslations from '../components/useTranslations'
import * as S from '../components/TerminalsList/styled'
import DocumentDownload from "../components/LandingPage/documentDownload"

const TerminalsList = ({ data, pageContext: { locale } }) => {
  const { retmonTitle, lang, navbar, seoDescriptions } = useTranslations()
  const TerminalsListTR = data.TR.edges
  const TerminalsListEN = data.EN.edges

  return (
    <div className="homepage">
      <SEO
        title={`${navbar.productsSub.dataCollection} | ${retmonTitle}`}
        description={seoDescriptions.terminalsList}
        lang={lang} />
      <S.Container>

          <S.Title>{navbar.productsSub.dataCollection}</S.Title>

        <S.RowSectionStart
        className='grid-list'
          justify='space-between'>
          {locale === 'en'
            ? TerminalsListEN.map(
              ({
                node: {
                  frontmatter: { subTitle, title, featuredImage, subURL, verticalImage },
                  fields: { slug },
                },
              }) => (
                  <LocalizedLink to={`/${subURL}/${slug}`}>
                    <S.BorderedColumn className='grid-item'>
                      <S.ColumnItems auto>
                        {
                          verticalImage === true ?
                            <S.FeaturedVerticalImage fluid={featuredImage.childImageSharp.fluid} alt={title} />
                            :
                            <S.FeaturedImage fluid={featuredImage.childImageSharp.fluid} alt={title} />
                        }
                      </S.ColumnItems>
                      <S.ColumnItems height>
                        <S.SubHeader bold>{title}</S.SubHeader>
                        <S.SubHeader3 >{subTitle}</S.SubHeader3>
                      </S.ColumnItems>
                    </S.BorderedColumn>
                  </LocalizedLink>
                )
            )
            : TerminalsListTR.map(
              ({
                node: {
                  frontmatter: { subTitle, title, featuredImage, subURL, verticalImage },
                  fields: { slug },
                },
              }) => (
                  <LocalizedLink to={`/${subURL}/${slug}`}>
                    <S.BorderedColumn className='grid-item'>
                      <S.ColumnItems auto>
                        {
                          verticalImage === true ?
                            <S.FeaturedVerticalImage fluid={featuredImage.childImageSharp.fluid} alt={title} />
                            :
                            <S.FeaturedImage fluid={featuredImage.childImageSharp.fluid} alt={title} />
                        }
                      </S.ColumnItems>
                      <S.ColumnText height >
                        <S.SubHeader bold>{title}</S.SubHeader>
                        <S.SubHeader3 >{subTitle}</S.SubHeader3>
                      </S.ColumnText>
                    </S.BorderedColumn>
                  </LocalizedLink>
                )
            )}
        </S.RowSectionStart>
        <S.DividerForRow />
      </S.Container>
    </div>
  )
}

export const query = graphql`
  query TerminalsList {
    EN: allMarkdownRemark(filter: {frontmatter: {page: {eq: "DataCollectionTerminals"}}, fields: {locale: {eq: "en"}}}, sort: {order: ASC, fields: frontmatter___order}) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 250)
          fields {
            slug
            locale
          }
          frontmatter {
            page
            subTitle
            title
            subURL
            verticalImage
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
    TR: allMarkdownRemark(filter: {frontmatter: {page: {eq: "DataCollectionTerminals"}}, fields: {locale: {eq: "tr"}}}, sort: {order: ASC, fields: frontmatter___order}) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 250)
          fields {
            slug
            locale
          }
          frontmatter {
            page
            subURL
            subTitle
            title
            verticalImage
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
  
`

export default TerminalsList
